import React, { useEffect, useContext, useRef, useCallback } from "react";
import { get } from "../utils";
import {
  Container,
  TextReveal,
  ScrollMore,
  Headline,
  WorkExperience,
  Layout,
  SEO,
} from "../components";
import { myContext } from "../provider";
import useOnScreen from "../hooks/useOnScreen";
import styled, { css } from "styled-components";
import { size } from "../theme/theme";

const Section = styled.div`
  z-index: 99;
  ${(props) => css`
    ${
      props.underHeader
        ? `
            height: calc(100vh - ${props.theme.spaces.headerHeight});
            margin: 10vh 0 -40vh 0;
            display: flex;
          `
        : css`
            margin: 140px 0 50px;
            @media ${size.m} {
              margin: 230px 0 80px;
            }
          `
    }
    ${
      props.snapScroll &&
      `
        scroll-snap-align: start;
      `
    }
    ${props.style}
  `};
`;

const ExperiencesPage = ({ data }) => {
  const context = useContext(myContext);

  const sectionBannerflow = useRef(null);
  const sectionNaturalCycles = useRef(null);
  const sectionQred = useRef(null);
  const sectionApegroup = useRef(null);

  const sectionBannerflowOnScreen = useOnScreen(sectionBannerflow);
  const sectionNaturalCyclesOnScreen = useOnScreen(sectionNaturalCycles);
  const sectionQredOnScreen = useOnScreen(sectionQred);
  const sectionApegroupOnScreen = useOnScreen(sectionApegroup);

  const onLoad = () => {
    const sections = [
      {
        key: 1,
        companyName: "bannerflow",
        title: "Landing in Sweden",
        ref: sectionBannerflow,
      },
      {
        key: 2,
        companyName: "natural cycles",
        title: "Trying myself as a manager",
        ref: sectionNaturalCycles,
      },
      {
        key: 3,
        companyName: "qred",
        title: "Back to the basics",
        ref: sectionQred,
      },
      {
        key: 4,
        companyName: "apegroup",
        title: "My current home",
        ref: sectionApegroup,
      },
    ];
    context.set_pageSections(sections);
  };

  useEffect(onLoad, []);

  const setCurrentSectionKey = useCallback(() => {
    if (sectionBannerflowOnScreen) {
      context.set_currentSectionKey(1);
    } else if (sectionNaturalCyclesOnScreen) {
      context.set_currentSectionKey(2);
    } else if (sectionQredOnScreen) {
      context.set_currentSectionKey(3);
    } else if (sectionApegroupOnScreen) {
      context.set_currentSectionKey(4);
    }
  });

  const getRefFromCompanyName = (companyName) => {
    switch (companyName) {
      case "Bannerflow":
        return sectionBannerflow;
      case "Natural cycles":
        return sectionNaturalCycles;
      case "Qred AB":
        return sectionQred;
      case "Apegroup AB":
        return sectionApegroup;
      default:
        return sectionBannerflow;
    }
  };

  useEffect(() => {
    setCurrentSectionKey();
  }, [
    sectionBannerflowOnScreen,
    sectionNaturalCycles,
    sectionQredOnScreen,
    sectionApegroupOnScreen,
    setCurrentSectionKey,
  ]);

  return (
    <Layout>
      <SEO
        title="Thomas Morice: My experiences in Sweden"
        description="After few different jobs as a backend developer in France, I moved to Sweden. Discover some of my experiences as a web developer since I joined this beautiful country. "
      />
      <Section>
        <Container>
          <div>
            <Headline
              leftElem={data.sanityExperiencePage.experienceLeftPartTitle}
              rightElem={data.sanityExperiencePage.experienceRightPartTitle}
            ></Headline>
          </div>
          <div>
            <h1>
              {data.sanityExperiencePage.title.map((elem) => (
                <TextReveal
                  rotate={elem.doRotate}
                  delay={elem.delay || undefined}
                  key={elem._key}
                >
                  {elem.text}
                </TextReveal>
              ))}
            </h1>
          </div>
          <div>
            <ScrollMore />
          </div>
        </Container>
      </Section>
      {data.sanityExperiencePage.experience.map((experience) => {
        return (
          <Section
            key={experience._key}
            ref={getRefFromCompanyName(experience.companyName)}
          >
            <WorkExperience
              title={experience.title}
              text={experience.text}
              companyName={experience.companyName}
              bannerColor={get(experience, "bannerColor.hex")}
              bannerPattern={experience.bannerPattern}
              technologies={experience.technologies}
              logo={experience.logo}
              illustration={experience.illustration}
              illustrationWidth={experience.illustrationWidth}
              illustrationBottomPosition={experience.illustrationBottomPosition}
              isTextDark={experience.isTextDark}
            ></WorkExperience>
          </Section>
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query experiencePageQuery {
    sanityExperiencePage {
      experienceLeftPartTitle
      experienceRightPartTitle
      title {
        _key
        text
        delay
        doRotate
      }
      experience {
        _key
        title
        text
        companyName
        bannerColor {
          hex
        }
        bannerPattern {
          asset {
            fixed(width: 400, height: 400) {
              ...GatsbySanityImageFixed
            }
          }
        }
        bottomLinkText
        technologies
        logo
        illustration {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        illustrationWidth {
          default
          small
          medium
          large
          xlarge
        }
        illustrationBottomPosition {
          default
          small
          medium
          large
          xlarge
        }
        isTextDark
      }
    }
  }
`;

export default ExperiencesPage;
